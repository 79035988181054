import * as SentrySvelte from '@sentry/svelte';
import type { HandleClientError } from '@sveltejs/kit';
import { dev } from '$app/environment';
import { env } from '$env/dynamic/public';

SentrySvelte.init({
  dsn: env.PUBLIC_SENTRY_DSN
});

SentrySvelte.setTag('svelteKit', 'browser');

// This will catch errors in load functions from +page.ts files
export const handleError = (({ error, event }): App.Error => {
  if (dev) {
    console.error(error);
  }

  const sentryEventId = SentrySvelte.captureException(error, {
    contexts: { sveltekit: { event } }
  });

  return {
    message: error instanceof Error ? error.message : 'Unknown error message',
    sentryEventId
  };
}) satisfies HandleClientError;
