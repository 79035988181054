/**
 * Contains an array of all localized months in lowercase letters
 */
export const localeMonths = ((): string[] => {
  const date = new Date(2000, 0); // January
  const months: string[] = [];
  for (let i = 0; i < 12; i++) {
    months.push(
      date
        .toLocaleString('nl-NL', {
          month: 'long'
        })
        .toLowerCase()
    );
    date.setMonth(i + 1);
  }
  return months;
})();
